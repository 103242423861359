$(document).ready(function () {
    initSelects();
    initProducts();
    initMenu();
    initOrderForm();
    initNumbers();
    initFilters();
    initItemCarousels();
    initGalleries();
    initLayout();
    initMobileMenu();
    initOrderHistory();
    initToTop();
    initDeadline();
    $(window).scroll();

    $.featherlight.defaults.closeIcon = '';
    $.featherlight.defaults.otherClose = '.close-calc';
    $.featherlight._callbackChain.beforeOpen = function (event) {
    };
    $.featherlight._callbackChain.afterClose = function (event) {
    };
});

function resize() {
    initLayout();
}

var timo;
window.onresize = function () {
    clearTimeout(timo);
    timo = setTimeout(resize, 100);
};

$('.navbar-toggler').click(function () {
    $(this).toggleClass('active');
});

function initSelects() {
    var selects = $('select.styled:not(.done)');
    styleSelects();

    $(document).on('click', 'select.styled', function () {
        $(this).parent().focus().toggleClass('clicked');
    });

    $(document).on('click', '.select-value', function () {
        $(this).parent().focus().toggleClass('clicked');
    });

    $(document).on('click', '.select-options .option', function () {
        var wrap = $(this).parents('.select-wrap');
        var selectValue = wrap.find('.select-value');
        wrap.removeClass('clicked');
        selectValue.text($(this).text())
        wrap.find('select').val($(this).attr('data-value')).change();
        $('.option', wrap).removeClass('selected');
        $(this).addClass('selected');
        if ($(this).attr('data-default')) {
            selectValue.removeClass('selected');
        }
        else {
            selectValue.addClass('selected');
        }
    });

    //find option by pressed keys
    var typingTimer;
    var doneTypingInterval = 700;
    var needle = '';

    $(document).on('keypress', ".select-wrap", function (e) {
        needle += e.key;
        clearTimeout(typingTimer);
        selectWrap = $(this);

        typingTimer = setTimeout(function () {
            doneTyping(selectWrap);
        }, doneTypingInterval);
    });

    function doneTyping(selectWrap) {
        var opt = $('.option', selectWrap).filter(function () {
            var text = $(this).text().toLowerCase().replace(/\s/g, '');
            needle = needle.replace(/\s/g, '');
            return text.indexOf(needle) === 0;
        });
        if (opt.length > 0) {
            selectWrap.find('select-options').mCustomScrollbar('scrollTo', opt.eq(0).position().top);
            opt.eq(0).click();
        }
        needle = '';
    }
}

function styleSelects() {
    var selects = $('select.styled:not(.done)');
    selects.each(function () {
        $(this).wrap('<div class="select-wrap"></div>');
        var wrap = $(this).parent();
        var selected = $(this).find(':selected');
        var def = $(this).find('[data-default="true"]');
        var notdefault = '';
        selected.attr('selected', true);

        if (selected.val() != def.val()) {
            notdefault = true;
        }
        wrap.append('<div class="select-value ' + (notdefault ? 'selected' : '') + '">' + selected.text() + '</div>');
        var html = '<div class="select-options">';
        $(this).find('option').each(function () {
            html += '<div class="option ' + ($(this).attr('selected') ? 'selected' : '') + '" data-value="' + $(this).val() + '" ' + ($(this).data('default') ? 'data-default="true"' : '') + '>' + $(this).text() + '</div>';
        });
        html += "</div>";

        wrap.append(html);
        wrap.addClass($(this).attr('class'));
        $(this).addClass('done');

        if ($('.select-options', wrap).outerHeight() >= parseInt($('.select-options', wrap).css('max-height'))) {
            $('.select-options', wrap).mCustomScrollbar();
        }
    });

    $('.select-wrap').attr('tabindex', '1').focusout(function () {
        $(this).removeClass('clicked');
    });
}

function initMenu() {
    var menuWrap = $('#header');
    var mainTop = $('#main-top');
    initActions(menuWrap);
    initActions(mainTop);
    initSmallCart();
    initCatalogMenu();
    initAutocomplete();

    function initAutocomplete() {
        var input = $('#search-needle');
        var suggestions = $('#autocomplete-suggestions');

        input.on('keyup', function (e) {
            if (e.keyCode === 27) {
                $('.visible').removeClass('visible');
            }
        })

        suggestions.mCustomScrollbar();
    }

    function initSmallCart() {
        $('.small-cart').mCustomScrollbar({
            scrollbarPosition: "outside"
        });

        $(window).scroll(function () {
            if ($('#form-small-cart').length > 0) {
                $('#form-small-cart').toggleClass('show', !checkVisible($('#cart').get(0), $('#cart').height() / 2, false));
            }
        });
    }

    //init again on first click to fix wrong offsets on inivisible menu
    $('.catalog-menu-wrap .catalog-toggler').click(function () {
        if (!$(this).parent().hasClass('inited')) {
            initCatalogMenu();
        }
        $(this).parent().addClass('inited');
    });

    function initCatalogMenu() {
        var w = $('.catalog-menu');
        var menuBottom = w.offset().top + w.outerHeight();

        $('#main-wrapper .submenu-wrap').css({
            'width': $('#main-wrapper .submenu-wrap').closest('.container').outerWidth()-15
        });

        $('.submenu-wrap', w).each(function () {
            if (w.parents('#mobile-menu').length > 0) {
                return;
            }
            var item = $(this).closest('.nav-item');
            var submenu = $(this).find('.submenu');
            if ($(this).data('inited')) {
                return;
            }
            var diff = Math.abs(w.offset().top - item.offset().top);
            var thisBottom = item.offset().top + submenu.height();

            if (diff < submenu.height() - 20) {
                submenu.offset({ top: submenu.offset().top - diff });
                return;
            }

            if (submenu.height() <= diff && thisBottom < menuBottom) {
                $(this).offset({ top: $(this).offset().top - ($(this).height() / 2) + (item.height() / 2) });
                return;
            }

            if (submenu.height() <= w.height()) {
                $(this).offset({ top: $(this).offset().top - Math.abs(menuBottom - thisBottom) - 10 });
                return;
            }
            $(this).attr('data-inited', true);
        });
    }
}
function initProducts() {
    var wrap = $('.product');

    initActions(wrap);

    wrap.on('focus blur', '.action-menu', function () {
        var wrap = $(this).closest('.product');
        $('.product').removeClass('focus');
        wrap.toggleClass('focus', $(this).hasClass('visible'));
    });
}

function initActions(wrap) {
    var actionWrap = $('.action-wrap', wrap);
    var actionMenu = $('.action-menu', wrap);
    var win = $(window);
    actionWrap.on('keyup', function (e) {
        if (e.keyCode === 27) {
            $('.visible').removeClass('visible');
        }
    });

    actionMenu.css({
        'max-height': $(window).height() - $('#header').height()
    })

    $(document).on('click', function (e) {
        var target = e.relatedTarget ? $(e.relatedTarget) : $(e.target);
        if (typeof target != 'undefined' && target != 'null') {
            if (target.parents('.action-menu').length > 0 || target.is('.action-menu')) {
            } else {
                $('.visible').not($('#to-top')).removeClass('visible');
            }
        } else {
            $('.visible').not($('#to-top')).removeClass('visible');
        }
    });

    $('.action-toggle', wrap).click(function () {
        var actionWrap = $(this).closest('.action-wrap');
        var menu = $('.action-menu', actionWrap);
        menu.width(menu.width());
        if (menu.length < 1) {
            return true;
        }
        $('.action-wrap .visible').filter(function (index, element) {
            return !$(element).parents('.action-wrap').is(actionWrap);
        }).removeClass('visible');

        var toggler = $('.action-toggle', actionWrap);
        menu.toggleClass('visible');
        toggler.toggleClass('visible', menu.hasClass('visible'));

        var rightMax = $(win).width() - 17;
        var menuLeft = menu.offset().left;
        var menuRight = menu + menu.width();

        if (menuRight > rightMax) {
            menu.offset({ left: menuLeft - Math.abs(rightMax - menuRight) * 2 });
            return false;
        }

        if (menuLeft <= 17) {
            menu.offset({ left: menuLeft + Math.abs(17 - menuLeft) });
            return false;
        }

        menu.attr('style', '');
        rightMax = $(win).width() - 17;
        menuLeft = menu.offset().left + menu.position().left / 2;
        menuRight = menuLeft + menu.width();
        if (menuRight > rightMax) {
            menu.offset({ left: menuLeft - Math.abs(rightMax - menuRight) * 2 });
        }

        return false;
    });
}

function initNumbers() {
    var numwraps = $('.numwrap');

    $('.num-control', numwraps).click(function () {
        var input = $(this).siblings('input');
        var min = parseInt(input.attr('min'));
        var max = parseInt(input.attr('max'));

        if ($(this).hasClass('minus')) {
            if (input.val() > min) {
                input.val(input.val() - 1);
            }
        } else {
            if (max) {
                if (input.val() >= max) {
                    return false;
                }
            }
            input.val(parseInt(input.val()) + 1);
        }
        return false;
    });

    $('input', numwraps).on('change keyup', function () {
        var input = $(this);
        var min = parseInt(input.attr('min'));
        var max = parseInt(input.attr('max'));

        if (input.val() > max) {
            input.val(max);
        }
        if (input.val() < min) {
            input.val(min);
        }
    })
}

function initFilters() {
    var wrap = $('#filters form');

    initSliderFilter();

    function initSliderFilter() {
        var sliderWraps = $('.slider-inputs', wrap);

        sliderWraps.each(function () {
            var minInput = $('.from', $(this)).eq(0);
            var maxInput = $('.to', $(this)).eq(0);
            var sliderEl = $('.slider', $(this)).eq(0);
            var sliderElSpan = $('input', sliderEl).eq(0);
            var minVal = parseInt(minInput.attr('min'));
            var maxVal = parseInt(maxInput.attr('max'));
            var min = sliderEl.data('min') ? parseInt(sliderEl.data('min')) : minVal;
            var max = sliderEl.data('max') ? parseInt(sliderEl.data('max')) : maxVal;
            var startValMin = minInput.val() ? minInput.val() : minVal;
            var startValMax = maxInput.val() ? maxInput.val() : maxVal;

            sliderElSpan.ionRangeSlider({
                type: "double",
                min: min,
                max: max,
                from: startValMin,
                from_min: minVal,
                from_max: maxVal,
                to: max,
                to_min: minVal,
                to_max: maxVal,
                hide_min_max: true,
                hide_from_to: true,
                onChange: function (data) {
                    minInput.val(data.from);
                    maxInput.val(data.to);
                }
            });

            var slider = sliderElSpan.data("ionRangeSlider");

            $("input[type='number']", $(this)).on("change", function () {
                if (minInput.val() < minVal || minInput.val() == '') {
                    minInput.val(minVal);
                }
                if (maxInput.val() > maxVal || maxInput.val() == '') {
                    maxInput.val(maxVal);
                }
                slider.update({
                    from: minInput.val(),
                    to: maxInput.val()
                });
            });
        });

    }
}

function initGalleries() {
    var galleries = $('.gallery');

    galleries.each(function () {
        var wrap = $(this);
        var mainImage = $('.main-image', wrap);
        var thumbWrap = $('.thumbnails', wrap);
        var thumbnails = $('.thumbnail', thumbWrap)

        thumbWrap.owlCarousel({
            items: 3,
            margin: 20,
        });

        $('.thumbnail', thumbWrap).click(function () {
            var current = thumbnails.index(thumbnails.filter('.current'));
            var next = thumbnails.index($(this));

            thumbnails.removeClass('current');
            $(this).addClass('current');
            $('.carousel', wrap).carousel($(this).data('slide'));

            scrollThumbs(current, next);

        });

        function scrollThumbs(current, next) {
            if (current < next) {
                thumbWrap.trigger('next.owl.carousel');
            }

            if (current > next) {
                thumbWrap.trigger('prev.owl.carousel');
            }
        }

        $('.carousel-item', mainImage)
            .on('mouseover', function () {
                $(this).parents('.gallery').addClass('hover');
                $(this).find('.photo').css({ 'transform': 'scale(' + $(this).attr('data-scale') + ')' });
            })
            .on('mouseout', function () {
                $(this).parents('.gallery').removeClass('hover');
                $(this).find('.photo').css({ 'transform': 'scale(1)' });
            })
            .on('mousemove', function (e) {
                $(this).find('.photo').css({ 'transform-origin': ((e.pageX - $(this).offset().left) / $(this).width()) * 100 + '% ' + ((e.pageY - $(this).offset().top) / $(this).height()) * 100 + '%' });
            })
            .each(function () {
                if (typeof $(this).attr('data-image') == 'undefined' || $(this).attr('data-image') == '') {
                    return;
                }
                var anchor = $(this).find('.embed-responsive-item').length > 0 ? $(this).find('.embed-responsive-item') : $(this);
                anchor
                    .append('<div class="photo"></div>');
                anchor
                    .find('.photo').css({ 'background-image': 'url(' + $(this).attr('data-image') + ')' });
            });
    });
}

function initOrderForm() {
    var wrap = $('.order-form-section');

    if (wrap.length < 1) {
        return;
    }

    wrap.on('change', 'input[type="radio"]', function () {
        var related = $('[data-selector="' + $(this).attr('name') + '"]');
        if (related.length < 1) {
            return;
        }

        var child = $('[data-parent="' + $(this).val() + '"]');
        related.hide();
        child.show();
    });

    $('input[type="radio"]:checked', wrap).change();
}

function initLayout() {
    var main = $('#main');
    mainHeight();
    wideBg();
    product();
    faq();
    blog();

    function blog() {
        if ($('article').length !== 1) {
            return;
        }

        if ($(window).width() < 576) {
            $('.product-block').eq(0).after($('.text-content+aside'));
        } else {
            $('.text-content').after($('.product-block+aside').eq(0));
        }
    }

    function faq() {
        if ($('.faq').length < 1) {
            return;
        }

        if ($(window).width() < 768) {
            $('.text-content .faq').eq(0).before($('.side-links'));
        } else {
            $('.text-content .side-links').prependTo($('.text-content+.sticky-top').eq(0));
        }
    }

    function product() {
        if ($('#product').length < 1) {
            return;
        }

        if ($(window).width() < 768) {
            $('#product .top h2').eq(0).after($('#product .gallery'));
        } else {
            $('#product h2+.gallery').appendTo($('#product .top .col-12:first-child').eq(0));
        }
    }

    function mainHeight() {
        var mainSiblings = main.siblings('section, div, header, footer');
        var heightSum = 0;

        mainSiblings.each(function () {
            heightSum += $(this).outerHeight() + parseInt($(this).css('margin-top')) + parseInt($(this).css('margin-bottom'));
        });

        main.css('min-height', $(window).height() - heightSum);
    }


    function wideBg() {
        var div = $('.order-form-section form >.row.summary', main);
        if (div.length < 1) {
            return;
        }
        if (div.find('.wide-bg').length < 1) {
            div.append('<div class="wide-bg"></div>');
        }
        var wideBg = div.find('.wide-bg');
        wideBg.width($(window).width());
        wideBg.offset({ 'left': 0 })
    }

}



function initToTop() {
    var tTop = $('#to-top');
    var footerTop = $(document).height() - $('footer').outerHeight();

    $(window).scroll(function () {
        var wScrollBot = $(this).scrollTop() + $(this).height();
        tTop.toggleClass('visible', wScrollBot >= $(window).height() * 1.5);
        tTop.toggleClass('static', wScrollBot >= footerTop);
    });

    tTop.on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({
            scrollTop: 0
        }, 500);
    });
}

function initMobileMenu() {
    var mainContent = $('#main-wrapper');
    var mobileMenu = $('#mobile-menu');
    var filters = $('#filters');
    var toggler = $('.navbar-toggler');
    var catalogMenu = $('#mobile-nav');
    var header = $('#header');
    var lastScrollTop = 0;
    var st = $(window).scrollTop();

    initMobileNavigation();
    initMobileFilters();

    function initMobileFilters() {
        $(document).on('click touchstart', function (e) {
            var target = e.relatedTarget ? $(e.relatedTarget) : $(e.target);
            if (typeof target != 'undefined' && target != 'null') {
                if (target.parents('#filters').length > 0 || target.is('#filters') || target.parents('.filter-toggler').length > 0 || target.is('.filter-toggler') || target.parents('#tooltip-light-box-bg').length > 0 || target.is('#tooltip-light-box-bg')) {
                } else {
                    hideMenu();
                }
            } else {
                hideMenu();
            }

            function hideMenu() {
                if (filters.hasClass('show')) {
                    mainContent.removeClass('pushed-left');
                    filters.removeClass('show');
                    $('.filter-toggler').removeClass('clicked');
                    setTimeout(function () {
                        filters.css({ top: 0 });
                        $('#header').css({ top: 0 });
                        $('html, body').removeClass('no-scroll');
                    }, 300);
                }
                return false;
            }
        });

        $(document).on('click', '.filter-toggler', function () {
            st = $(window).scrollTop();
            filters.toggleClass('show');
            mainContent.toggleClass('pushed-left', filters.hasClass('show'));
            $('.filter-toggler').toggleClass('clicked', filters.hasClass('show'));
            if (filters.hasClass('show')) {
                $('html, body').addClass('no-scroll');
                $('#header').css({ 'top': st });
                filters.css({ 'top': st });
                filters.scrollTop(0);
            }
            else {
                $('html, body').removeClass('no-scroll');
                $('#header').css({ 'top': 0 });
                filters.css({ 'top': 0 });

            }

            return false;
        });
    }

    function initMobileNavigation() {
        $(document).on('click touchstart', function (e) {
            var target = e.relatedTarget ? $(e.relatedTarget) : $(e.target);
            if (typeof target != 'undefined' && target != 'null') {
                if (target.parents('#mobile-menu').length > 0 || target.is('#mobile-menu') || target.parents('.navbar-toggler').length > 0 || target.is(toggler)) {
                } else {
                    hideMenu();
                }
            } else {
                hideMenu();
            }

            function hideMenu() {
                if ($(mobileMenu).hasClass('show')) {
                    mainContent.removeClass('pushed-right');
                    mobileMenu.removeClass('show');
                    setTimeout(function () {
                        $('#header').css({
                            top: 0
                        });
                        $('html, body').removeClass('no-scroll');
                    }, 300);
                }
                return false;
            }
        });

        toggler.click(function () {
            st = $(window).scrollTop();
            mobileMenu.toggleClass('show');
            mainContent.toggleClass('pushed-right', mobileMenu.hasClass('show'));
            if (mobileMenu.hasClass('show')) {
                $('html, body').addClass('no-scroll');
                $('#header').css({ 'top': st });
                catalogMenu.scrollTop(0);
            }
            else {
                $('html, body').removeClass('no-scroll');
                $('#header').css({ 'top': 0 });
            }

            return false;
        });

        catalogMenu.css({
            'max-height': mobileMenu.height() - $('.langmenu', mobileMenu).outerHeight() - 10
        })

        catalogMenu.on('click', '.submenu-toggle', function (e) {
            e.stopPropagation();
            var row = $(this).closest('.nav-row');
            var activeCol = row.children('.column.show');
            var thisCol = $(this).closest('.column');
            var nextCol = thisCol.next('.column');
            var clickedItem = $(this).closest('.nav-item');

            $('.nav-row', catalogMenu).not(row).addClass('gone').animate({
                height: 0
            }, 150);

            $('.column', row).removeClass('show');
            activeCol.addClass('gone');
            nextCol.addClass('show');
            nextCol.children('.mobile-submenu').hide();
            nextCol.children('.mobile-submenu').eq(clickedItem.index()-1).show();
            row.animate({
                height: nextCol.height()
            }, 150);
        });

        catalogMenu.on('click', '.back', function (e) {
            var row = $(this).closest('.nav-row');
            var activeCol = row.children('.column.show');
            var thisCol = $(this).closest('.column');
            var prevCol = thisCol.prev('.column');

            $('.column', row).removeClass('show');
            if (prevCol.index() === 0) {
                $('.nav-row', catalogMenu).not(row).removeClass('gone').each(function () {
                    $(this).animate({
                        height: $('.column', $(this)).eq(0).get(0).scrollHeight
                    }, 150)
                });
            }
            prevCol.addClass('show').removeClass('gone');
            row.animate({
                height: prevCol.get(0).scrollHeight
            }, 150);

            return false;
        });
    }

    $(document).on('keyup', function (e) {
        if (e.keyCode === 27) {
            mobileMenu.removeClass('show');
            filters.removeClass('show');
            $('.pushed-left').removeClass('pushed-left');
            $('.pushed-right').removeClass('pushed-right');
        }
    });

    $(window).scroll(function () {
        header.toggleClass('scrolled', $(this).scrollTop() > 10);
        var st = $(this).scrollTop();
        $('.no-scroll #header').css({ 'top': st });

        if (st > lastScrollTop) {
            header.addClass('hide-search');
        } else {
            header.removeClass('hide-search');
        }
        lastScrollTop = st;
    });
}

function initOrderHistory() {
    if ($('#history').length < 1) {
        return;
    }

    initActions($('#history'));
    initCalendar();

    $('.history-item').on('click', '.toggle', function () {
        var historyItem = $(this).closest('.history-item');
        var historyItemContent = historyItem.find('.history-item-content');

        historyItem.toggleClass('expanded');
        if (historyItem.hasClass('expanded')) {
            historyItemContent.slideDown(300);
        } else {
            historyItemContent.slideUp(300);
        }
        return false;
    });

    function initCalendar() {
        $.dateRangePickerLanguages.custom = translations;
        var config = {
            inline: true,
            startOfWeek: 'monday',
            container: '#calendar',
            alwaysOpen: true,
            singleMonth: true,
            showShortcuts: true,
            showTopbar: true,
            language: 'custom',
            separator: $.dateRangePickerLanguages.custom.to,
            hoveringTooltip: false,
            format: 'DD.MM.YYYY',
            customArrowPrevSymbol: ' ',
            customArrowNextSymbol: ' ',
            shortcuts:
                {
                    'prev': ['week', 'month', 'year'],
                },
        }

        $('#calendar').dateRangePicker(config).bind('datepicker-change', function (event, obj) {
            var dateFrom = moment(obj.date1);
            var dateTo = moment(obj.date2);

            $('#filter-date-from').val(dateFrom.format('DD.MM.YYYY'));
            $('#filter-date-to').val(dateTo.format('DD.MM.YYYY'));

            $('#date-filter-form').submit();
        });

        if ($('#filter-date-from').val()) {
            $('#calendar').data('dateRangePicker').setStart($('#filter-date-from').val());
        }
        if ($('#filter-date-to').val()) {
            $('#calendar').data('dateRangePicker').setEnd($('#filter-date-to').val());
        }
    }
}

function checkVisible(elm, threshold, mode) {

    threshold = threshold || 0;
    mode = mode || 'visible';

    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    var above = rect.bottom - threshold < 0;
    var below = rect.top - viewHeight + threshold >= 0;

    return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
}

var locationCoords,
    geocoder,
    mapObject,
    currentMarker;

var allMapMarkers = [];

function initLocationAutocomplete() {
    var autocompleteInput = $('#autocomplete-location');

    autocompleteObj = new google.maps.places.Autocomplete(
        (document.getElementById('autocomplete-location')),
        {
            types: ['geocode'],
        });

    autocompleteObj.addListener('place_changed', processSelectedAddress);

    $('#autocomplete-location').on('keyup', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            forceGeocode();
            e.preventDefault();
        }
    });

    $('#autocomplete-location').on('keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    $('#submit-autocomplete').click(function () {
        forceGeocode();
    });
}

function processSelectedAddress() {
    addressObject = autocompleteObj.getPlace();

    if (typeof addressObject.geometry == 'undefined') {
        return;
    }

    locationCoords = {
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
    }

    map.setCenter(locationCoords);
}

function initPackageGoogleMap(map, markers, mapZoom, currentpos) {

    locationCoords = currentpos;
    getAddressFromPosition(currentpos);

    mapObject = new google.maps.Map(map, {
        zoom: mapZoom,
        center: locationCoords,
        mapTypeControl: false,
        gestureHandling: 'greedy'
    });


    $.each(markers, function () {
        putMarkerOnMap(this);
    });

    putMarkerOnMap({
        coords: locationCoords,
        defaultIcon: '/images/html/current_location.svg'
    }, true);

}

function putMarkerOnMap(marker, current) {
    map = mapObject;

    var mapMarker = new google.maps.Marker({
        position: marker.coords,
        map: map,
        draggable: false,
        icon: marker.defaultIcon ? marker.defaultIcon : false,
        defaultIcon: marker.defaultIcon ? marker.defaultIcon : false,
        selectedIcon: marker.selectedIcon ? marker.selectedIcon : false,
        customProperties: marker.customProperties
    });

    if (current) {
        currentMarker = mapMarker;
    } else {
        allMapMarkers.push(mapMarker);
    }

    google.maps.event.addListener(mapMarker, 'click', function () {
        if(typeof currentMarker != 'undefined'){
            currentMarker.setIcon(currentMarker.defaultIcon);
        }
        currentMarker = mapMarker;
        mapMarker.setIcon(mapMarker.selectedIcon);

        processPackagePointSelect(this);
    });

}

function forceGeocode() {
    geocoder = geocoder ? geocoder : new google.maps.Geocoder();
    geocoder.geocode({
        address: $('#autocomplete-location').val(),
    }, function (results, status) {
        var result = results[0];

        locationCoords = {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
        };

        map.setCenter(locationCoords);
        removeMarkerFromMap(currentMarker);
        putMarkerOnMap({
            coords: locationCoords,
            defaultIcon: '/images/html/current_location.svg'
        }, true);

        formattedAddress = result['formatted_address'];
        $('#autocomplete-location').val(formattedAddress);
    });
    return false;
}

function removeMarkerFromMap(marker) {
    marker.setMap(null);
}

function getAddressFromPosition(pos) {
    geocoder = geocoder ? geocoder : new google.maps.Geocoder();

    geocoder.geocode({
        latLng: pos
    }, function (responses) {
        var result = responses[0];

        formattedAddress = result['formatted_address'];
        $('#autocomplete-location').val(formattedAddress);
    });
}

function initItemCarousels() {
    var carousels = $('.item-carousel');

    carousels.each(function () {
        var wrap = $(this).closest('.carousel-wrap');
        var owl = $(this).addClass('owl-carousel').owlCarousel({
            items: 6,
            loop: true,
            controls: true,
            dots: false,
            autoplay: true,
            autoplayTimeout: 5000,
            responsive: {
                1: {
                    items: 1,
                },
                480: {
                    items: 2,
                },
                600: {
                    items: 3,
                },
                768: {
                    items: 4,
                },
                900: {
                    items: 5,
                },
                992: {
                    items: 4,
                },
            }
        });

        wrap.find('.c-control').click(function () {
            if ($(this).hasClass('carousel-prev')) {
                owl.trigger('prev.owl.carousel');
            } else {
                owl.trigger('next.owl.carousel');
            }
            return false;
        });
    });
}

function initDeadline() {
    if($('#deadline-calendar').length < 1){
        return false;
    }
    $.dateRangePickerLanguages.custom = translations;
    var config = {
        inline: true,
        startOfWeek: 'monday',
        container: '#deadline-calendar',
        alwaysOpen: true,
        singleMonth: true,
        showShortcuts: false,
        showTopbar: false,
        language: 'custom',
        separator: $.dateRangePickerLanguages.custom.to,
        hoveringTooltip: false,
        format: 'DD.MM.YYYY',
        customArrowPrevSymbol: ' ',
        customArrowNextSymbol: ' ',
        singleDate: true,

    }

    $('#deadline-calendar').dateRangePicker(config).bind('datepicker-change', function (event, obj) {
        var dateFrom = moment(obj.date1);
        $('#deadline-date').val(dateFrom.format('DD.MM.YYYY'));
        $('.deadline-wrap span.to').text(dateFrom.format('DD.MM.YYYY'));

    });

    if ($('#deadline-date').val()) {
        $('#deadline-calendar').data('dateRangePicker').setStart($('#deadline-date').val());
    }

    initActions('#projects');
}